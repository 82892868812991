.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
}
.m-0 {
  margin: 0;
}
.primary-bg {
  background: #76839d;
}
.tertiary-bg {
  background: #03bfd3;
}
.primary-text {
  color: #76839d;
}
.tertiary-text {
  color: #03bfd3;
}
.blue-bg {
  background: #1b223c;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.margin-right-oneem {
  margin-right: 1em;
}

.margin-left-oneem {
  margin-left: 1em;
}

.container {
  width: 100vw;
  min-height: 100vh;
  background-color: #1b223c;
  overflow-y: scroll;
}

.full-width,
.full-width .MuiFormControl-marginNormal,
.full-width.form-input {
  width: 100%;
}

.column-between {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ai-center {
  align-items: center;
}
.page-content {
  flex: 1;
}
.header {
  width: 100%;
  min-height: 35vh;
  position: sticky;
  display: block;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}
.header-small-height {
  min-height: 10vh;
}
.row {
  display: flex;
  flex-direction: row;
}
.header-offset {
  min-height: 35vh;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.flex-row-left {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: left;
  align-items: center;
}

.flex-center {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  width: 30vw;
  top: 40vh;
  position: absolute;
  left: 35vw;
  height: 9em;
}
.spinner {
  height: 6rem;
  width: 6rem;
  margin: 0 auto;
  background-image: url('./assets/loader.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70% auto;
}
.spinner-text {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 101;
}

.nav-link {
  font-size: 18px;
  font-weight: bold;
  color: #5a8dee;
  border: none;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  text-decoration: none;
  padding-bottom: 1rem;
}

.nav-link.active {
  border: none;
  border-bottom: 5px solid #76839d !important;
  color: #76839d !important;
}

.form-input-group {
  margin-bottom: 1.25rem;
  width: 90%;
}

.form-input-group .error-text {
  margin-top: 0.5rem;
  color: red;
}

.form-input {
  border-radius: 50px;
  border: 1px solid #76839d;
  padding: 15px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 2px;
  display: flex;
  margin: auto;
  width: 85%;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.form-submit-arrow {
  width: 75px;
  margin: auto;
}

.submit-btn {
  background: #5a8dee;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2em;
  border: none;
  padding: 0.5em 1em;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.form-terms {
  color: #76839d;
  font-weight: bold;
  font-size: 0.75rem;
  text-transform: none;
  margin-bottom: -1rem;
}

.form-terms-link a {
  color: #5a8dee;
  font-weight: bold;
  font-size: 0.75rem;
  text-transform: none;
}

.form-input-with-icon {
  padding-left: 2.5rem;
}

.form-input-username {
  background: url(./assets/username.png) no-repeat scroll 0.75rem;
  background-size: contain;
  background-size: 1rem;
}

.form-input-email {
  background: url(./assets/email.png) no-repeat scroll 0.75rem;
  background-size: contain;
  background-size: 1rem;
}

.form-input-password {
  background: url(./assets/password.png) no-repeat scroll 0.75rem;
  background-size: contain;
  background-size: 1rem;
}

.form-input-container {
  min-height: 20vh;
  padding: 1rem;
}

/* forum */
.form-arrow-down-border {
  border: 1px solid #76839d !important;
}
.form-arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #03bfd3;
  margin: 0.5rem auto;
}

.form-pop-up-input-group {
  margin-bottom: 0.5rem;
  width: 90%;
}

.form-pop-up-input-group .error-text {
  margin-top: 0.5rem;
  color: red;
}

.flex {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.jc-center {
  justify-content: center;
}

.float-container {
  color: white;
  border: 1px solid #fff;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.float-child {
  padding: 10px;
  min-width: 5em;
}

.float-child-value {
  padding: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-field {
  border: 1px solid;
  border-radius: 4px;
}

.select-field .css-1fyzmik-control {
  border: 0px;
  padding: 8px 10px;
}

.select-field .css-1g2549z-control,
.css-1g2549z-control:hover {
  border: 0px !important;
  border-color: transparent !important;
  box-shadow: transparent !important;
  padding: 8px 10px;
}

.normal-text {
  color: white;
}

.swal-modal {
  background-color: #262e48;
}

.swal-title,
.swal-text {
  color: white;
}

.swal-button {
  background: #5a8dee;
}

@media only screen and (max-width: 600px) {
  .main-text {
    font-size: 20px;
  }
}

.capitalized {
  text-transform: capitalize;
}

.profile-name {
  color: white;
  font-size: 24px;
}

.profile-email {
  color: white;
  font-size: 10px;
  margin-top: -20px;
}

.profile-row-with-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 10px;
  align-self: center;
  margin-top: 20px;
}

.profile-row-section {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.profile-row-with-space .profile-sub-text {
  font-size: 12px;
  color: white;
  font-weight: 300;
}

.info-button {
  color: #ffffff;
  background: #5a8dee;
  font-weight: bold;
  font-size: 1em;
  border: 1px solid #ffffff;
  padding: 0.1em 0.6em;
  border-radius: 50%;
  font-style: italic;
  font-family: 'Poppins', sans-serif;
}

.info-button-right {
  float: right;
}

.share-buttons-container {
  width: fit-content;
  margin: 1em;
  float: left;
}